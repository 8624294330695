<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>Live TV</sub-title>
                <div class="board_list livetvfram">
                    Live
                    <iframe scrolling="no" frameborder="0" allowtransparency="true" style="width: 100%;height: 1400px"
                            src="https://rod.livetv01.com"></iframe>

                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import SubTitle from "../../components/SubTitle";
    import sportsConst from "../../common/sportsConst";

    export default {
        name: "LiveTV",
        components: {SubTitle, FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp},
        data() {
            return {
                sportsConst
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    .livetvfram {
        box-sizing: border-box;
        padding: 20px 50px;
    }
    .livetvfram iframe{
        width: 100%;
        min-height: 560px;
    }
    @media screen and (max-width: 1024px) {
        .livetvfram{
            padding: 20px 5px;
        }
    }

</style>